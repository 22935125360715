import { useCallback } from 'react'

import { Button, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { IconCircleCheck } from '@tabler/icons'
import { usePostApiV1AuthUserInit } from '@testsa-com/common/src/codegen/generated/auth-users'
import { AuthHelper, type TSAErrorType } from '@testsa-com/common/src/helpers/Auth.helper'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import validator from 'validator'

import genericStyles from '../../styles/generic.module.scss'
import styles from '../../styles/pages/Login.module.scss'

const Login = () => {
  const userInitMutation = usePostApiV1AuthUserInit()
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value: string) => (validator.isEmail(value) ? null : t('login.error_invalidEmail')),
    },
    validateInputOnBlur: true,
  })
  const { t } = useTranslation('common')
  const [, setClientToken] = useLocalStorage({ key: AuthHelper.AUTH_CLIENT_TOKEN_NAME })

  const onLogin = useCallback(
    async (formData: { email: string }) => {
      try {
        const { body } = await userInitMutation.mutateAsync({
          data: {
            email: formData.email,
          },
        })

        setClientToken(body.clientToken as string)
      } catch (e) {
        console.error(e)
      }
    },
    [setClientToken, userInitMutation],
  )

  return !userInitMutation.isSuccess ? (
    <form onSubmit={form.onSubmit(onLogin)}>
      <TextInput
        type="email"
        label={t('words.emailAddress')}
        placeholder="adresse@domaine.fr"
        size="md"
        {...form.getInputProps('email')}
      />
      <Button type="submit" fullWidth mt="xl" size="md" loading={userInitMutation.isLoading}>
        {t('login.getLoginLink')}
      </Button>
      {userInitMutation.isError && (
        <p className={genericStyles.error}>
          {AuthHelper.getErrorMessage(userInitMutation.error as TSAErrorType)}
        </p>
      )}
    </form>
  ) : (
    <>
      <h5 className={styles.message}>
        <IconCircleCheck size={40} />
        <span>{t('login.emailSent')}</span>
      </h5>
      <Link href="/login/validate">
        <p className={styles.validateLink}>{t('login.alreadyHaveLoginToken')}</p>
      </Link>
    </>
  )
}

export default Login
