/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Test SA
 * TEST SA API Documentation
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  PostApiV1AuthUserInit200,
  PostApiV1AuthUserInitApiBodies,
  PostApiV1AuthUserValidate200,
  PostApiV1AuthUserValidateApiBodies
} from './Api.schemas'
import { customInstance } from '../AxiosInstance';
import type { ErrorType } from '../AxiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Session was initialized successfully (if the user exists) or not (if the user does not exist)
 */
export const postApiV1AuthUserInit = (
    postApiV1AuthUserInitApiBodies: PostApiV1AuthUserInitApiBodies,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostApiV1AuthUserInit200>(
      {url: `/api/v1/auth/user/init`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV1AuthUserInitApiBodies
    },
      options);
    }
  


export const getPostApiV1AuthUserInitMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthUserInit>>, TError,{data: PostApiV1AuthUserInitApiBodies}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthUserInit>>, TError,{data: PostApiV1AuthUserInitApiBodies}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AuthUserInit>>, {data: PostApiV1AuthUserInitApiBodies}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AuthUserInit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AuthUserInitMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AuthUserInit>>>
    export type PostApiV1AuthUserInitMutationBody = PostApiV1AuthUserInitApiBodies
    export type PostApiV1AuthUserInitMutationError = ErrorType<unknown>

    export const usePostApiV1AuthUserInit = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthUserInit>>, TError,{data: PostApiV1AuthUserInitApiBodies}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AuthUserInit>>,
        TError,
        {data: PostApiV1AuthUserInitApiBodies},
        TContext
      > => {

      const mutationOptions = getPostApiV1AuthUserInitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Validate authentication with an user auth token
 */
export const postApiV1AuthUserValidate = (
    postApiV1AuthUserValidateApiBodies: PostApiV1AuthUserValidateApiBodies,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostApiV1AuthUserValidate200>(
      {url: `/api/v1/auth/user/validate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV1AuthUserValidateApiBodies
    },
      options);
    }
  


export const getPostApiV1AuthUserValidateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthUserValidate>>, TError,{data: PostApiV1AuthUserValidateApiBodies}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthUserValidate>>, TError,{data: PostApiV1AuthUserValidateApiBodies}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AuthUserValidate>>, {data: PostApiV1AuthUserValidateApiBodies}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AuthUserValidate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AuthUserValidateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AuthUserValidate>>>
    export type PostApiV1AuthUserValidateMutationBody = PostApiV1AuthUserValidateApiBodies
    export type PostApiV1AuthUserValidateMutationError = ErrorType<unknown>

    export const usePostApiV1AuthUserValidate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthUserValidate>>, TError,{data: PostApiV1AuthUserValidateApiBodies}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AuthUserValidate>>,
        TError,
        {data: PostApiV1AuthUserValidateApiBodies},
        TContext
      > => {

      const mutationOptions = getPostApiV1AuthUserValidateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    